import { createApolloFetch } from 'apollo-fetch'
import { setParamStrict, getUrlProductInfo, getApiURL, getBaseURL } from './utils/url'

function main(element: HTMLElement, hostname: string) {
  const { shortKey = null, productType = null } = getUrlProductInfo(hostname) || {}

  if (!shortKey) {
    element.innerHTML = "Short key is missing"
    return
  }

  if (!productType) {
    element.innerHTML = "Missing product param"
    return
  }

  const fetch = createApolloFetch({
    uri: getApiURL(),
  })

  fetch({
    query: `
      query OrganizationKeys {
        organizations {
          id
          namespaces {
            id
            shortKey
            namespace
          }
        }
      }
    `,
  }).then(res => {
    const organizations = res?.data?.organizations || []

    for (const organization of organizations) {
      for (const namespace of organization.namespaces) {
        if (namespace.shortKey !== shortKey) {
          continue
        }

        const { namespace: productNamespace } = namespace
        // TODO: Consider comparing namespace.product with productType
        // Note that productType could be onboarding which is an alias for app while namespace.product only has app

        const domain = getBaseURL(productType)
        const { pathname, hash } = window.location
        const query = setParamStrict({ namespace: productNamespace })

        const redirectURL = `${domain}${pathname}${query}${hash}`

        window.location.href = redirectURL
        return
      }
    }


    element.innerHTML = "No product match found"
    return
  })
}

const el = document.getElementById('main')

if (el) {
  main(el, window.location.hostname)
}
