enum Environment {
    'production', 'staging', 'testing', 'development'
}

const getEnvironmentFromURL = (): Environment => {
    const { hostname } = window.location

    // remove org short key from hostname
    // example: vgs.workshop.ccars.io => workshop.ccars.io
    const domain =  hostname.substring(hostname.indexOf('.') + 1)

    switch (domain) {
        case 'workshop.ccars.io':
        case 'fleet.ccars.io':
        case 'app.ccars.io':
        case 'onboarding.ccars.io':
            return Environment.production
        case 'workshop.staging.ccars.io':
        case 'fleet.staging.ccars.io':
        case 'app.staging.ccars.io':
        case 'onboarding.staging.ccars.io':
        case 'localhost':
        default:
            return Environment.staging
    }
}

export function getApiURL() {
    switch (getEnvironmentFromURL()) {
        case Environment.production:
            return 'https://api.connectedcars.io/graphql'
        case Environment.staging:
        case Environment.development:
        case Environment.testing:
        default:
            return 'https://api.staging.connectedcars.io/graphql'
    }
}

export function getBaseURL(productType: string) {
    if (getEnvironmentFromURL() === Environment.production) {
        switch (productType) {
            case 'fleet':
                return 'https://fleet.connectedcars.io'
            case 'workshop':
                return 'https://workshop.connectedcars.io'
            case 'app':
                return 'https://app.connectedcars.io'
            case 'onboarding':
                return 'https://onboarding.connectedcars.io'
            default:
                throw new Error('Invalid product type')
        }
    }

    switch (productType) {
        case 'fleet':
            return 'https://fleet.staging.connectedcars.io'
        case 'workshop':
            return 'https://workshop.staging.connectedcars.io'
        case 'app':
            return 'https://app.staging.connectedcars.io'
        case 'onboarding':
            return 'https://onboarding.staging.connectedcars.io'
        default:
            throw new Error('Invalid product type')
    }
}

export const setParamStrict = (values: object) => {
    const params = {}
    const query = window.location.search
    if (query) {
        const items = query.substr(1).split('&')
        for (const item of items) {
            const parts = item.split('=')
            params[parts[0]] = decodeURIComponent(parts[1])
        }
    }
    for (const key in values) {
        if (values[key] != null) {
            params[key] = values[key]
        } else {
            delete params[key]
        }
    }

    const items = []
    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            items.push(`${key}=${encodeURIComponent(params[key])}`)
        }
    }

    return `?${items.join('&')}`
}

export function getUrlProductInfo(hostname: string) {
    const regex = /([a-z0-9]+)\.([a-z0-9]+)[\.staging,\.testing]*\.ccars\.io$/i
    const match = regex.exec(hostname.toLowerCase())

    if (!match) {
        return null
    }

    return {
        shortKey: match[1],
        productType: match[2],
    }
}
